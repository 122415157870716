<script setup lang="ts">
import { ref, computed } from "vue";
import { mdiClose, mdiCog } from "@mdi/js";
import { useI18n } from "vue-i18n";
import { useBtnSelectOptions } from "../../composables/useBtnSelectOptions";
import { useThemeStorage } from "../../composables/useThemeStorage";
import MDialog from "../MDialog.vue";
import MDivider from "../MDivider.vue";
import MCard from "../MCard.vue";
import MBtn from "../MBtn.vue";
import MBtnSelect from "../MBtnSelect.vue";
import MIcon from "../MIcon.vue";

interface Props {
  locales?: {
    code: string;
    locale: string;
    name: string;
  }[];
}

// props
const { locales } = defineProps<Props>();

// composables
const { t } = useI18n();
const { storageTheme: theme } = useThemeStorage();

const { options: themeOptions } = useBtnSelectOptions({
  options: computed(() => [
    { label: t("light"), value: "light", color: "primary" },
    { label: t("dark"), value: "dark", color: "primary" },
    { label: t("system"), value: "system", color: "primary" },
  ]),
});

const { options: localeOptions } = useBtnSelectOptions({
  options: locales
    ? computed(() =>
        locales.map((locale) => ({
          label: locale.name,
          value: locale.code,
          color: "primary",
        }))
      )
    : undefined,
});

// states
const dialog = ref(false);

const locale = defineModel<string>({
  required: false,
});
</script>

<template>
  <MDialog
    v-model="dialog"
    class="mx-auto max-w-xl"
  >
    <template #activator="{ props }">
      <slot
        name="activator"
        :props="props"
      >
        <MBtn
          color="default"
          v-bind="props"
          :prependIcon="mdiCog"
          variant="text"
        >
          {{ t("app-settings") }}
        </MBtn>
      </slot>
    </template>
    <MCard>
      <div class="m-4 flex items-center justify-between">
        <h1 class="text-3xl">{{ t("app-settings") }}</h1>
        <MBtn
          type="button"
          icon
          color="default"
          size="small"
          variant="tonal"
          @click="dialog = false"
        >
          <MIcon :icon="mdiClose" />
        </MBtn>
      </div>
      <MDivider />
      <div class="mb-6 text-center md:mx-20">
        <MCard
          class="mx-2 my-6"
          variant="flat"
        >
          <h2 class="pt-6 text-lg md:text-2xl md:font-bold">
            {{ t("change-theme") }}
          </h2>
          <div class="my-6 flex justify-center">
            <MBtnSelect
              v-model="theme.name"
              :options="themeOptions"
              :border="false"
              btnSize="default"
            />
          </div>
        </MCard>

        <MCard
          v-if="locales?.length"
          class="mx-2 my-6"
          variant="flat"
        >
          <h2 class="pt-6 text-lg md:text-2xl md:font-bold">
            {{ t("language") }}
          </h2>
          <div class="my-6 flex justify-center">
            <MBtnSelect
              v-model="locale"
              :options="localeOptions"
              :border="false"
              btnSize="default"
            />
          </div>
        </MCard>
      </div>
    </MCard>
  </MDialog>
</template>
